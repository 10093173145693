body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Medium";
  src: local("Montserrat-Medium"), url("assets/fonts/Montserrat-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "Montserrat MediumItalic";
  src: local("Montserrat-MediumItalic"), url("assets/fonts/Montserrat-MediumItalic.ttf") format("truetype");
}


@font-face {
  font-family: "Montserrat SemiBold";
  src: local("Montserrat-SemiBold"), url("assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}


@font-face {
  font-family: "Montserrat Bold";
  src: local("Montserrat-Bold"), url("assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat Italic";
  src: local("Montserrat-Italic"),
    url("assets/fonts/Montserrat-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("assets/fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto-Bold"), url("assets/fonts/Roboto-Bold.ttf") format("truetype");
}